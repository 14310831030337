<template>
<div class="link-view">
  <div class="links">
      <div class="link" v-for="(link, key) in store.links" v-bind:key="key">
        <a v-bind:href="linkUrl(link)" class="button">
            <div class="inline"><i v-bind:class="linkIcon(link)" v-if="linkIcon(link)" /><div>{{ linkText(link) }}</div></div>
        </a>
      </div>
  </div>
  <div class="post-links" v-if="store.postlinks">
      <div class="link" v-for="(link, key) in store.postlinks" v-bind:key="key">
          <a v-bind:href="linkUrl(link)" class="button">
            <img v-if="link.image" v-bind:src="link.image" />
            {{ linkText(link) }}
        </a>
      </div>
  </div>
</div>
</template>

<script>
import { store } from '../store';
import { getLinkText, getLinkURL, getLinkIcon } from '../link-helper';

export default {

    data() {
        return {
            store
        }
    },
    mounted() {

    },
    methods: {
        linkText(link) {
            return getLinkText(link);
        },
        linkUrl(link) {
            return getLinkURL(link);
        },
        linkIcon(link) {
            return getLinkIcon(link);
        }
    }
}
</script>

<style>

.links {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 95vw;
    width: 24em;
    padding-bottom: 5px;
}

.post-links {
    margin-top: 1em;
    padding-top: 1em;
    padding-bottom: 5px;
    border-top: 1px dashed rgb(248, 202, 218);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 95vw;
    width: 24em;
}

a {
    text-decoration: none;
}

.inline {
    display: flex;
    justify-content: center;
}

.button {
    display: flex;
    flex-direction: column;
    background-color: var(--button-color);
    color: var(--font-color);
    padding: .5em .5em .3em .5em;
    border-radius: 3px;
    margin: 0.2em;
    font-family: var(--button-font);
    align-content: center;
    justify-content: center;
    font-weight: bold;
    font-size: 1.2em;
    line-height: 2em;
    box-shadow: 5px 5px 0px 0px var(--button-shadow-color);
    border-bottom: 5px solid transparent;
}

.button img {
    width: 100%;
}

.link:hover .button {
    transform: translateY(2px);
    background-color: var(--button-hover-color);
    box-shadow: 3px 3px 0px 0px var(--button-shadow-color);
}


i.fa-brands {
    display: flex;
    align-content: center;
    justify-content: center;
    line-height: 2em;
    width: 2em;
    height: 2em;
    border-radius: 2em;
    margin-right: 1em;
    color: #fff;
    background-color: var(--font-color);

}

</style>