if (!String.prototype.format) {
    String.prototype.format = function() {
        var args = arguments;
        return this.replace(/{(\d+)}/g, function(match, number) { 
            return typeof args[number] != 'undefined'
            ? args[number]
            : match
            ;
        });
    };
}

const LinkList = {
    etsy: "https://www.etsy.com/shop/{0}",
    instagram: "https://www.instagram.com/{0}"
};

const TextList = {
    etsy: "Etsy Shop",
    instagram: "Instagram"
};

const IconList = {
    etsy: "fa-brands fa-etsy",
    instagram: "fa-brands fa-instagram"
}

function getLinkURL(link) {
    let url = LinkList[link.type];
    if (url) {
        return url.format(link.link);
    }

    return link.link;
}

function getLinkText(link) {
    let text;

    if (!link.text) {
        text = TextList[link.type];
        if (!text) {
            text = link.link.replace(/([htsf]*tp[s]*:\/\/)/g, '');
        }
    }

    if (!text) {
        text = link.text;
    }

    return text;
}

function getLinkIcon(link) {
    return IconList[link.type];
}

export { getLinkURL, getLinkText, getLinkIcon }
