<template>
  <div class="content">
    <div class="logo"><img src="./assets/500x500LogoWHITE.png" /></div>
    <h1>noiris.art</h1>
    <LinkList></LinkList>
  </div>
</template>

<script>
import { store } from './store';
import LinkList from './components/LinkList.vue';

export default {
  name: 'App',
  components: {
    LinkList
  },
  data() {
    return {
      store
    }
  },
  beforeMount() {
    fetch('links.json').then(response => response.json()).then(data => {
      store.links = data['links'];
      store.postlinks = data['post-links'];
      store.social = data['social'];
    });
  }

}

</script>

<style>
@import url(default.css);


body {
  display: flex;
  margin: 0px;
  padding: 0px;
  min-width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  flex-direction: column;
  background: var(--background-bottom-color);
  background: linear-gradient(0deg, var(--background-bottom-color) 0%, var(--background-top-color) 100%);
  background-attachment: fixed;
}

h1 {
  font-family: 'Comfortaa', sans-serif;
  color: var(--title-color);
  text-shadow: 1px 1px 3px rgba(0,0,0,.5);
  margin-top: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
  margin-top: 60px;
}

.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  
}

.logo {
  display: flex;
  width: var(--logo-size);
  height: var(--logo-size);
}

.logo img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.logo.round {
  overflow: hidden;
  border-radius: 50%;
}

</style>
